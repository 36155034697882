import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from "gatsby";
import TitleLogo from "./../title-logo/title-logo";

const AboutUsPage = () => {
  return (
    <section >
      <div>
        <TitleLogo siteTitle={"aboutUs"} siteComment={<span><a href="/" className="text-black"><Trans>home</Trans></a> </span>} />
      </div>
      <section className="about-us">
        <div className="container" id="aboutus">
          <div className="row" >
            <div className="col-12 bg-white" id="slide-button">
              <p className="questrial-50"><Trans>Biz Kimiz?</Trans></p>
              <p className="questrial-25"><Trans>about-who-desc</Trans></p>
              <p className="questrial-50 pt-4"><Trans>What are we doing?</Trans></p>
              <p className="questrial-25"><Trans>about-doing-desc</Trans></p>
              <StaticImage className="logo mt-4 mb-5" src="../../images/kbo-logo.png" height={80} quality={100} alt="kbo-logo" />
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}
export default AboutUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;