import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from 'gatsby-plugin-react-i18next';
import { Link } from "gatsby"
import "./title-logo.scss"


const titleLogo = ({ siteTitle, siteComment }) => {
    return (
        <>
            {/* <section className="hero">
                <div className="container-fluid">
                    <div className="row bg-w-white bg-size">
                        <div className="col-12 hero-height">
                            <div className="down hero-height">
                                <div className="row text-center d-flex align-items-end hero-height">
                                    <div className="col-lg-4 col-md-12 align-items-end  order-lg-1 order-1 hero-align mb-2">
                                        <Link to="/products">
                                            <h4 className="title-head"><Trans>{siteTitle}</Trans></h4>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-12 order-lg-2 order-3 pt-3 slide-bottom slide-button-image">
                                        <a href="#slide-button">
                                            <StaticImage className="mt-4" src="../../images/pages/index/down.png" alt="kbo-logo" />
                                        </a>
                                    </div>
                                    <div className="col-lg-4 col-md-12 order-lg-3 order-2  pt-4 hero-align">
                                        <p className="title-text"><Trans>{siteComment}</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="hero">
                <div className="container-fluid">
                    <div className="row bg-w-white bg-size">
                        <div className="col-12 hero-height bg-title">
                            <div className="down hero-height">
                                <div className="row text-center d-flex align-items-end hero-height ">
                                    <div className="col-lg-4 col-md-12 align-items-end  order-lg-1 order-1 hero-align mb-2">
                                        <Link to="/products">
                                            <h4 className="title-head"><Trans>{siteTitle}</Trans></h4>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-12 order-lg-2 order-3 pt-3 slide-bottom slide-button-image">
                                        {/* <a href="#slide-button">
                                            <StaticImage className="mt-4" src="../../images/pages/index/down.png" alt="kbo-logo" />
                                        </a> */}
                                    </div>
                                    <div className="col-lg-4 col-md-12 order-lg-3 order-2  pt-4 hero-align">
                                        <p className="title-text"><Trans>{siteComment}</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}


export default titleLogo
