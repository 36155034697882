import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutUsPage from "../components/about-us/about-us";
import {graphql} from "gatsby";

const AboutUs = () => (
    <Layout>
        <Seo title="About Us" />
        <AboutUsPage/>
    </Layout>
)

export default AboutUs
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;